// extracted by mini-css-extract-plugin
export var automotiveBenefitsSection = "W_jr";
export var automotiveCasesSection = "W_jw";
export var automotiveClientQuotesSection = "W_jB";
export var automotiveDevExpertiseSection = "W_jy";
export var automotiveIndustriesSection = "W_jq";
export var automotiveProcessSection = "W_js";
export var automotiveRequirementsSection = "W_jv";
export var automotiveServicesSection = "W_jp";
export var automotiveSkilledSection = "W_jz";
export var automotiveTechStackSection = "W_jt";
export var automotiveWhyHireSection = "W_jx";